import React from 'react';
import { Button, StyleSheet, Text, TextInput, View, ScrollView, Alert } from 'react-native';
import { GetRequest, PutRequest } from '../../services/dataservice';
import SplashScreen from '../splash';
import { styles } from '../../styles';
import ModalDialog from '../modal';

class SettingScreen extends React.Component {
  modalDialogRef;

  constructor(props:any) {
    //console.log("constructor()..");    
    super(props);    
    this.state = {
      name: "SettingScreen",
      isLoading: true,
      //settings: [],    
      values: [],
      epr: {},
      password: {},
      ipcc: {},
      pccs: {},
      isEprChanged: false,
      isPwdChanged: false,
      isIpccChanged: false,
      isPccsChanged: false,
    };

    this.modalDialogRef = React.createRef();
  }
  render() {
    //console.log('render()..');
    if (this.state.isLoading == true) {
      return <SplashScreen />
    }
    return (
      <View style={styles.container}>
        <ScrollView>
          <Text style={styles.headerText}>Settings</Text>
          {this.renderBody()}
          {/* <Button
            title="Login"
            //onPress={() => navigation.navigate('Home')}
            onPress={() => this.props.navigation.push('Home')}
          /> */}
        </ScrollView>
        <ModalDialog ref={this.modalDialogRef} />
      </View>
    );
  }
  componentDidMount () {
    //console.log('componentDidMount()..');
    this.fetchSettingData();
    this.loaded();
  }
  loading() {
    this.setState({
      isLoading: true,
    });
  }
  loaded() {
    this.setState({
      isLoading: false,
    });
  }
  fetchSettingData() {
    //console.log("fetchSettingData()..");
    this.loading();
    GetRequest('api/sysConfigs')
    .then((jsonData) => {
      //console.log('jsonData=');
      //console.log(jsonData);
      jsonData.map((data) => {
        //console.log('data=');
        //console.log(data);
        if (data.name=="wsSetting.Password") {
          this.setState({
            password: data,
            isPwdChanged: false,
          });
        } else if (data.name=="wsSetting.Username") {
          this.setState({
            epr: data,
            isEprChanged: false,
          });
        } else if (data.name=="wsSetting.Ipcc") {
          this.setState({
            ipcc: data,
            isIpccChanged: false,
          });
        } else if (data.name=="ProcessOnPCCs") {
          this.setState({
            pccs: data,
            isPccsChanged: false,
          });
        }
      })
      // this.setState({
      //   settings: jsonData,
      // })
    })
    .finally(() => {
      this.loaded();
    });
  }
  renderBody() {
    //console.log("renderBody()..");
    //console.log(this.state);
    return (
      <View>
        <View>
          <Text style={styles.textBold}>{this.state.epr.showName}:</Text>
          <TextInput 
            style={styles.input}
            value={this.state.epr.value}
            onChangeText={(val) => {                  
              var newVal = this.state.epr;
              newVal.value = val;
              this.setState({
                epr: newVal,
                isEprChanged: true,
              })
            }} 
          />
          <Text style={styles.textBold}>{this.state.password.showName}:</Text>
          <TextInput 
            style={styles.input}
            value={this.state.password.value}
            onChangeText={(val) => {                  
              var newVal = this.state.password;
              newVal.value = val;
              this.setState({
                password: newVal,
                isPwdChanged: true,
              })
            }} 
          />
          <Text style={styles.textBold}>{this.state.ipcc.showName}:</Text>
          <TextInput 
            style={styles.input}
            value={this.state.ipcc.value}
            onChangeText={(val) => {                  
              var newVal = this.state.ipcc;
              newVal.value = val;
              this.setState({
                ipcc: newVal,
                isIpccChanged: true,
              })
            }} 
          />
        </View>
        <View>
          <Text style={styles.textBold}>{this.state.pccs.showName}:</Text>
          <TextInput 
            style={styles.input}
            value={this.state.pccs.value}
            onChangeText={(val) => {                  
              var newVal = this.state.pccs;
              newVal.value = val;
              this.setState({
                pccs: newVal,
                isPccsChanged: true,
              })
            }}
            multiline
            editable
            numberOfLines={10}
            maxLength={500} 
          />
        </View>
        <View style={{flex: 2, flexDirection: 'row'}}>
          <View style={{flex: 1, width: '40%', padding: 10, }}>
            <Button
              title='Reset'
              disabled={ !(this.state.isEprChanged | this.state.isIpccChanged | this.state.isPwdChanged | this.state.isPccsChanged) }
              onPress={() => this.fetchSettingData()}
            />
          </View>
          <View style={{flex: 1, width: '40%', padding: 10,}}>
            <Button
              title='Update'
              disabled={ !(this.state.isEprChanged | this.state.isIpccChanged | this.state.isPwdChanged | this.state.isPccsChanged) }
              onPress={() => {
                var isSaved = false;
                if (this.state.isEprChanged == true) {
                  this.loading();
                  PutRequest('api/sysConfigs/' + this.state.epr.id, this.state.epr)
                  .then((jsonData) => {
                    //console.log(jsonData);
                    this.setState({
                      isEprChanged: false,
                    });
                    isSaved = true;
                  })
                  .finally(() => { 
                    this.loaded();
                  });
                }
                if (this.state.isPwdChanged == true) {
                  this.loading();
                  PutRequest('api/sysConfigs/' + this.state.password.id, this.state.password)
                  .then((jsonData) => {
                    //console.log(jsonData);
                    this.setState({
                      isPwdChanged: false,
                    });
                    isSaved = true;
                  })
                  .finally(() => { 
                    this.loaded();
                  });
                }
                if (this.state.isIpccChanged == true) {
                  this.loading();
                  PutRequest('api/sysConfigs/' + this.state.ipcc.id, this.state.ipcc)
                  .then((jsonData) => {
                    //console.log(jsonData);
                    this.setState({
                      isIpccChanged: false,
                    });
                    isSaved = true;
                  })
                  .finally(() => { 
                    this.loaded();
                  });
                }
                if (this.state.isPccsChanged == true) {
                  this.loading();
                  PutRequest('api/sysConfigs/' + this.state.pccs.id, this.state.pccs)
                  .then((jsonData) => {
                    //console.log(jsonData);
                    this.setState({
                      isPccsChanged: false,
                    });
                    isSaved = true;
                  })
                  .finally(() => { 
                    this.loaded();
                  });
                }

                if (isSaved) {
                  this.showMsg("Updated");
                }
              }}
            />
          </View>
        </View>
      </View>
    );
  }
  showMsg(msg:string): void {
    //console.log('showMsg()..called');
    //console.log('msg=', msg);

    this.modalDialogRef.current.showDetail("SET_MODAL", "info", msg);

    //console.log('showMsg()..end');
  }
}

export default SettingScreen;