import React, { useImperativeHandle, forwardRef } from "react";
import { Alert, Modal, StyleSheet, Text, Pressable, View } from "react-native";
import { Feather } from '@expo/vector-icons';
import { styles } from "../../styles";

const ModalDialog = (props:any, ref:any) => {
  useImperativeHandle(ref, () => ({
    // methods connected to 'ref'
    show: (error:any) => { showError(error) },
    showDetail: (type:any, icon:string, message:any) => {
      //console.log("type=", type);
      //console.log("icon=", icon);
      //console.log("message=", message);

      let error;
      if (Array.isArray(message)) {
        error = {
          icon: icon,
          messages: message,
        };
      }
      else {
        error = {
          icon: icon,
          messages: [ message ]
        };
      }

      showError(error);
    }
  }));
  const showError = (error:any) => {
    //console.log("showError(error) called");
    //console.log("error=", error);
    
    //console.log("(error != null && error != undefined) => " + (error != null && error != undefined));
    if (error != null 
      && error != undefined) {
      try {
        var msg = "";

        Object.keys(error).map(function(key) {
          if (key === "messages" && Array.isArray(error[key])) {
            error[key].map((value:any) => { msg += value + "\n" });
          }
        });
        //console.log("msg='" + msg + "'");

        if (error.code === "INTERNAL-ERROR") {
          console.error(error);

          dispatch({ 
            type: "SET_MODAL", 
            icon: error.icon,
            message: error.title
          });
        }
        else {
          dispatch({ 
            type: "SET_MODAL", 
            icon: error.icon,
            message: msg 
          });
        }
      } catch(e) {
        console.warn(e);
      }
    }
    //console.log("showError(error) end");
  };

  const[state, dispatch] = React.useReducer(
    (prevState:any, action:any) => {
      //console.log("action=", action);
      switch(action.type) {
        case 'SET_MODAL':
            return {
              ...prevState,
              visible: true,
              message: action.message.length === 0 
                ? "Something faulty"
                : action.message,
              icon: action.icon === undefined 
                ? prevState.icon 
                : action.icon,
              button: action.buttonMessage === undefined 
                ? "OK" 
                : action.button
            };
        case 'RESET_MODAL': 
          return {
            ...prevState,
            visible: false,
            message: '',
            icon: null,
            button: ' OK '
          };
        default:
          return prevState;
      }
    }, 
    { 
      visible: false,
      message: '',
      icon: null,
      button: ' OK '
    });
  return(
    <Modal
      animationType="slide"
      transparent={false}
      visible={state.visible}
      onRequestClose={() => { Alert.alert('Modal has been closed.'); dispatch({type:"RESET_MODAL"}); }}>
      <View style={styles.centeredView}>
        <View style={styles.modalView}>
        {state.icon === null 
          ? <Feather name="alert-circle" size={48} color="blue" />
          : state.icon === "warning"
          ? <Feather name="alert-triangle" size={48} color="orange" />
          : state.icon === "error"
            ? <Feather name="alert-triangle" size={48} color="red" />
            : <Feather name="alert-circle" size={48} color="blue" /> }
        <Text style={styles.modalText} >{state.message} </Text>
        <Pressable style={[styles.button, styles.buttonClose]} onPress={() => {dispatch({type:"RESET_MODAL"})}}>
          <Text style={styles.textStyle}> {state.button} </Text>
        </Pressable>
        </View>
      </View>
    </Modal>
  );
}

export default forwardRef(ModalDialog);