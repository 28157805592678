import React from "react";
import { Text, View } from 'react-native';
import { GetAPIVersion } from "../../services/dataservice";
import { styles } from "../../styles";

class AppVersion extends React.Component {
  constructor(props:any) {
    super(props);
    this.state = {
        webVersion: '',
        apiVersion: '',
    }
  }
  render(): React.ReactNode {
    const { apiVersion, webVersion } = this.state;
    return (
      <View style={{ flexDirection: 'row' }}>
        <Text style={styles.textVersion}>UI Ver: {webVersion}    API Ver: {apiVersion}</Text>
      </View>
    );
  }
  componentDidMount() {
    //this.loaded();
    var pjson = require('../../package.json');

    GetAPIVersion().then((apiVersion) => {
      //console.log('apiVersion=', apiVersion);
      this.setState({ apiVersion: apiVersion, webVersion: pjson.version, });  
    }).finally(() => { });
  }
}

export default AppVersion;