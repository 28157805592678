import React from 'react';
import { Button, StyleSheet, Text, TextInput, View, Alert, Platform, Image } from 'react-native';
import { AuthContext } from '../../services/authcontext';
import SplashScreen from '../splash';
import ModalDialog from '../modal';
import { ValidateEmail } from '../../services/dataservice';
import { styles } from '../../styles';
import AppVersion from '../version';

class LoginScreen extends React.Component  {
  modalDialogRef;

  constructor(props:any) {
    super(props);
    this.state = {
      email: '',
      password: '',
      isCanLogin: false,
      isLoading: true,
      validateEmailMessage: '',
    };
    this.modalDialogRef = React.createRef();
  }
  static contextType = AuthContext;
  showError(msgError:string) {
    //console.log("showError()..");
    this.modalDialogRef.current.showDetail("SET_MODAL", "error", [ msgError ]);
  }
  doLogin(username:string, password:string) {
    //console.log('doLogin()..called');
    //console.log("username=" + username + " [" + username.length + "]");
    //console.log("password=" + password + " [" + password.length + "]");
    //console.log("this.context");
    //console.log(this.context);

    //const { signIn } = React.useContext(AuthContext);

    if (username.length === 0) {
      this.showError('Please, enter username.');
    } else if (password.length === 0) {
      this.showError('Please, enter password.');
    } else if(!ValidateEmail(username)) {
      this.showError('Please, username is invalid.');
    } else {
      //this.loading();
      this.context.signIn({ email: username, password: password }, this.modalDialogRef.current.showDetail)
      .finally(() => {
        //console.log('doLogin()..finally');
        //this.loaded();
      });
    }
    //console.log('doLogin()..end');
  }
  setUsername(val) {
    this.setState({
      email: val,
    });
    this.validateInput(val, this.state.password);
  }
  setPassword(val) {
    this.setState({
      password: val,
    });
    this.validateInput(this.state.email, val);
  }
  validateInput(email:string, password:string) {
    //console.log('validateInput()..called');

    //console.log("email=", email);
    //console.log("password=", password);

    if (email === '') {
      this.setState({
        isCanLogin: false,        
        validateEmailMessage: "",
      });
    } else if (password === '') {
      this.setState({
        isCanLogin: false,        
        validateEmailMessage: "",
      });
    }
    if (!ValidateEmail(email)) {
      this.setState({
        isCanLogin: false,
        validateEmailMessage: "Email is invalid.",
      });
    } 
    if (ValidateEmail(email) 
     && email !== ''
     && password !== '') {
      this.setState({
        isCanLogin: true,
        validateEmailMessage: "",
      });
    } else {
      this.setState({
        isCanLogin: false,
      });
    }

    //console.log('validateInput()..end');
  }
  render() {
    const { isCanLogin, validateEmailMessage } = this.state;

    //const [username, setUsername] = React.useState('');
    //const [password, setPassword] = React.useState('');
    
    //console.log('contextType=');
    //console.log(this.contextType);

    if (this.state.isLoading) {
      return <SplashScreen />
    }
    
    return (
      <View style={styles.container}>        
        <Text style={styles.headerText}>Login</Text>
        <form>
        <View style={{paddingTop: 20,}}>
        <Text style={styles.textBold}>Username (Email):</Text>
        <TextInput 
          style={styles.input}
          placeholder='Email'
          value={this.state.email}        
          onChangeText={(val) => {
            this.setUsername(val);
          }}
        />
        <Text style={styles.errorText}>{validateEmailMessage}</Text>
        </View>
        <View>
        <Text style={styles.textBold}>Password:</Text>
        <TextInput 
          style={styles.input}
          placeholder='Password'
          value={this.state.password}        
          onChangeText={(val) => {
            this.setPassword(val);
          }}
          secureTextEntry
        />
        </View>
        <View>
          <Button 
            title='Login'
            disabled={!isCanLogin}
            onPress={() => {
              this.doLogin(this.state.email, this.state.password);
            }}
          />
        </View>
        {/* <Button  
          onPress={ () => {this.showAlert2() }}  
          title="Button 2"  
          color="#009933"  
        /> */}
        <ModalDialog ref={this.modalDialogRef} />
        </form>
        <AppVersion />
      </View>
    );
  }
  loading() {
    this.setState({
      isLoading: true,
    });
  }
  loaded() {
    this.setState({
      isLoading: false,
    });
  }
  componentDidMount() {
    this.loaded();
  }
  showMsg(msg:string): void {
    //console.log('showMsg()..called');
    //console.log('msg=', msg);

    this.modalDialogRef.current.showDetail("SET_MODAL", "info", msg);

    //console.log('showMsg()..end');
  }
  showAlert2() {
    console.log("showAlert2()..called");
    this.showMsg("Hello World!");
    console.log("showAlert2()..end");
  }
}

LoginScreen.contextType = AuthContext;

export default LoginScreen;