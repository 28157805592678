import AsyncStorage from '@react-native-async-storage/async-storage';
import jwt_decode from 'jwt-decode';
import { version } from 'react';
import { AuthContext } from './authcontext';

const API_ENDPOINT = "/";
//const API_ENDPOINT = "https://cds-kh-sandbox.travelpro.in.th/";
//const API_ENDPOINT = "https://localhost:7286/";

//let token = '';
// try {
//     var pomise = AsyncStorage.getItem('userToken');
//     console.log('pomise=');
//     console.log(pomise);
// }
// catch(e) {
//     console.error(e);
//}

async function GetAPIVersion() {
  //console.log("GetAPIVersion()..called");

  let version:string|null = '-';

  var responseData = await fetch(`${API_ENDPOINT}api/infos`, {
    method: 'GET'
  }).then((response) => {
    //console.log('response=', response);

    if (response.status === 200) {
      var contenttype = response.headers.get("Content-Type") ?? 'en';
      return contenttype.indexOf("json") >= 0 ? response.json(): response.text();        
    }
    return { version: '0.0.0' };
  }).then((json) => {
    //console.log('json=', json);

    version = json.version;
    return version;
  }).catch((e) => {
    //console.error('error:', e);
    return e;
  }).finally(() =>{ });

  //console.log("GetAPIVersion()..end");
  return responseData;
}

async function getToken() {
    let userToken:string|null = null;

    try {
         userToken = await AsyncStorage.getItem('userToken');
        // debug
        //console.log('userToken=', userToken);
        
        if (userToken === 'undefined'
          || userToken === null) {
            userToken = '';
        } else {
          if (userToken !== '') {
            const jwt = jwt_decode(userToken);
            //console.log("jwt=", jwt);
            if (jwt != undefined) {
              if (jwt.exp < Date.now() / 1000) {
                //AuthContext.signOut();
                userToken = '';
              }
            }
          }
        }
    } catch(e) {
        // error reading value
        console.error("error:", e);
        console.error("dump: userToken=", userToken);
    }

    return userToken;
}

async function GetCurrentUser() {
    //console.log('GetCurrentUser()..');

    let value = { username: '', role: '', canExportPcc: '', ownerPcc: '', agentName: '' };

    try {
        const userToken = await AsyncStorage.getItem('userToken');
        if (userToken != null 
          && userToken != undefined
          && userToken != '') {
            const jwt = jwt_decode(userToken);
            //console.log("jwt=", jwt);
            if (jwt != undefined) {
              let user = await GetRequest('api/users/profile');
              //console.log("user=", user);
              // { "id": 1, "email": "admin@cds.com.kh", "roles": [ "Administrator" ], "canExportPcc": null, "ownerPcc": null }
              if (user !== null
                && user !== undefined) {
                value = {
                  username: jwt.sub,
                  role: jwt["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"],
                  canExportPcc: user.canExportPcc,
                  ownerPcc: user.ownerPcc,
                  agentName: user.agentName,
                };
              }
            }
        }

        return value;
    } catch(e) {
        // error reading value
        console.error(e);
        return '';
    }
}

async function GetRequest(uri: string) {
    //console.log('GetRequest(uri)..');
    let token = await getToken();
    //console.log(token);

    const lang = await 'en';
    var responseData = await fetch(API_ENDPOINT + uri, {
        method: 'GET',
        headers: {
            Accept: 'text/plain',
            Authorization: 'Bearer ' + token,
            'Accept-Language': lang
        }
    })
    .then((response) => {
        if(response.status >= 400){
            throw response;
        }else{
            var contenttype = response.headers.get("Content-Type") ?? 'en';
            return contenttype.indexOf("json") >= 0 ? response.json(): response.text();
        }  
    }).then((json) => { 
        //console.log(json);
        return json;
    }).catch((error) => { 
        //console.error(error);
        return error;
    }).finally(() => { });

    //console.log(responseData);
    return responseData;
}

async function PostRequest(uri: string, data: undefined){
    //console.log('PostRequest(uri, data)..');
    let token = await getToken();
    //console.log(token);

    var postData = data === undefined ? {} : data;    
    const lang = 'en';

    var responseData = await fetch(API_ENDPOINT + uri, {
        method: 'POST',
        headers: {
            Accept: 'text/plain',
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Accept-Language': lang
        },
        body: JSON.stringify(postData)
    })
    .then((response) => {
        if(response.status >= 400){
            throw response;
        }else{
            var contenttype = response.headers.get("Content-Type") ?? 'en';
            return contenttype.indexOf("json") >= 0 ? response.json(): response.text();
        }  
    }).then((json) => { 
        //console.log(json);
        return json;
    }).catch((error) => { 
        console.error(error);
        return error;
    }).finally(() => { });

    //console.log(responseData);
    return responseData;
    return true;
}

async function PutRequest(uri: string, data: undefined){
    
    let token = await getToken();

    var postData = data === undefined ? {} : data;
    const lang = 'en';

    var responseData = await fetch(API_ENDPOINT + uri, {
        method: 'PUT',
        headers: {
            Accept: 'text/plain',
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Accept-Language': lang
        },
        body: JSON.stringify(postData)
    })
    .then((response) => {
        if(response.status >= 400){
            throw response;
        }else{
            var contenttype = response.headers.get("Content-Type") ?? 'en';
            return contenttype.indexOf("json") >= 0 ? response.json(): response.text();
        }  
    }).then((json) => { 
        //console.log(json);
        return json;
    }).catch((error) => { 
        //console.error(error);
        return error;
    }).finally(() => { });

    //console.log(responseData);
    return responseData;
    return true;
}

const ValidateEmail = (text:string) => {
  //console.log("text=", text);
  let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;

  return reg.test(text);
//   if (reg.test(text) === false) {
//     console.log("Email is Not Correct");
//     //setState({ email: text })
//     return false;
//   }
//   else {
//     //setState({ email: text })
//     console.log("Email is Correct");
//   }
}

export { API_ENDPOINT, GetRequest, PostRequest, PutRequest, getToken, GetCurrentUser, ValidateEmail, GetAPIVersion };