import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

export default function SplashScreen() {
  const loader = require('./loader.json');
  //console.log('loader=');
  //console.log(loader);
  return (
    <View style= { styles.container } >
      <Text>Loading...</Text>
    </View>
  );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
    },
});