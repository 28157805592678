import React from 'react';
import { StyleSheet, Text, View, Button, Linking } from 'react-native';
import SplashScreen from '../splash';
import { API_ENDPOINT, GetCurrentUser, PostRequest } from '../../services/dataservice';
import { styles } from '../../styles';
import { AuthContext } from '../../services/authcontext';
import AppVersion from '../version';

class HomeScreen extends React.Component {
  constructor(props:any) {
    //console.log("constructor()..");
    super(props);    
    this.state = {
      isLoading: true,
      userRole: '',
      username: '',
      fromDate: new Date().toISOString().split("T")[0],
      toDate: new Date().toISOString().split("T")[0],
      isCanExport: false,
      exportMsg: '',
    }
  }
  static contextType = AuthContext;
  componentDidMount () {
    //console.log('componentDidMount()..');
    this.loaded();
    GetCurrentUser().then((data) => {
      this.setState({
        username: data.username,
        userRole: data.role,
      })
    });
  }
  loading() {
    this.setState({
      isLoading: true,
    });
  }
  loaded() {
    this.setState({
      isLoading: false,
    });
  }
  render(): React.ReactNode {
    if (this.state.isLoading) {
      return (<SplashScreen />);
    }
    
    return (
      <View style={styles.containerHome}>
        {this.renderExportReport()}
        <View style={{padding: 10, flexDirection: 'row',}}>
          <View style={{padding: 5,}}>
            <Button
              title="Report Details"
              onPress={() => this.props.navigation.navigate('Reports')}
            />
          </View>
          <View style={{padding: 5,}}>
            <Button
              title="Real Time"
              onPress={() => this.props.navigation.navigate('RealTime')}
            />
          </View>
          {(this.state.userRole === 'Administrator' 
            ? <View style={{padding: 5,}}>
                <Button
                  title="Setting"
                  onPress={() => this.props.navigation.navigate('Settings')}
                />
              </View>
            : <></>
          )}        
          <View style={{padding: 5,}}>
            <Button
              title="Profile"
              onPress={() => this.props.navigation.navigate('Profile')}
            />
          </View>
          {(this.state.userRole === 'Administrator' 
            ? <View style={{padding: 5,}}>
                <Button
                  title="Users"
                  onPress={() => this.props.navigation.navigate('Users')}
                />
              </View>
            : <></>
          )}
        </View>
        <AppVersion />
      </View>
    );
  }
  exportReport() {
    const { fromDate, toDate } = this.state;

    //console.log('fromDate=', fromDate);
    //console.log('toDate=', toDate);

    // get export token
    let exportToken = '';
    PostRequest('api/exporttokens', { forReportDate: fromDate })
    .then((jsonData:any) => {
      //console.log('jsonData=', jsonData);
      
      if (jsonData.ok === false 
       && jsonData.status === 401) {
        this.context.signOut();
      } else {
        exportToken = jsonData.exportToken;
      }
    })
    .finally(async () => {
      if (exportToken !== '') {
        // https://localhost:7286/api/ReportExports?Token=b74b2618-6887-46e9-98fb-cac3c1925625&FromDate=2022-12-23&ToDate=2022-12-31
        let url = `${API_ENDPOINT}api/ReportExports?Token=${exportToken}&FromDate=${fromDate}&ToDate=${toDate}`;
        //console.log('url=', url);
        // Checking if the link is supported for links with custom URL scheme.
        const supported = await Linking.canOpenURL(url);
    
        if (supported) {
          // Opening the link with some app, if the URL scheme is "http" the web link should be opened
          // by some browser in the mobile
          await Linking.openURL(url);
        } else {
          alert(`Don't know how to open this URL: ${url}`);
        }
      }
    })
  }
  setFromDate(e:any) {
    //console.log("setFromDate(e)..called");

    //console.log('e=', e);
    //console.log('e.target.value=', e.target.value);
    this.setState({
      fromDate: e.target.value,
    });

    let now = new Date();
    let date = new Date(e.target.value);
    let toDate = new Date(this.state.toDate);
    let maxDate = new Date(e.target.value);

    //console.log("maxDate=", maxDate);

    maxDate.setMonth(maxDate.getMonth() + 1);
    maxDate.setDate(maxDate.getDate() - 1);

    //console.log("date=", date);
    //console.log("maxDate=", maxDate);

    if (date > now) {
      date = now;
    } else if(maxDate > now) {
      maxDate = now;
    } 
    
    if (toDate > maxDate) {
      toDate = maxDate;
    } else if (toDate < date) {
      toDate = date;
    }
    try {
      const strDate = date.toISOString();
      const strToDate = toDate.toISOString();
      const strDates = strDate.split("T");
      const strToDates = strToDate.split("T");

      //console.log("strDate=", strDate);
      //console.log("strToDate=", strToDate);
      //console.log("strDates=", strDates);
      //console.log("strToDates=", strToDates);

      const sDate = strDates[0];
      const sTDate = strToDates[0];

      this.setState({
        fromDate: sDate,
        toDate: sTDate,
      });

      this.validateExportReport(sDate, sTDate);
    } catch(e) {
      console.error("e=", e);
    }

    //console.log("setFromDate(e)..end");
  }
  setToDate(e:any) {
    //console.log("setToDate(e)..called");
    //console.log('e=', e);
    //console.log('e.target.value=', e.target.value);
    // this.setState({
    //   toDate: e.target.value,
    // });

    let now = new Date();
    let date = new Date(e.target.value);
    let fromDate = new Date(this.state.fromDate);
    let minDate = new Date(e.target.value);

    minDate.setDate(minDate.getDate() + 1);
    minDate.setMonth(minDate.getMonth() - 1);

    //console.log("now=", now);
    //console.log("date=", date);
    //console.log("fromDate=", fromDate);
    //console.log("minDate=", minDate);

    if (date > now) {
      date = now;
    } 
    
    if (fromDate > date) {
      fromDate = date;
    } else if (fromDate < minDate) {
      fromDate = minDate;
    }

    const sDate = date.toISOString().split("T")[0]
    const sFDate = fromDate.toISOString().split("T")[0];
    this.setState({
      fromDate: sFDate,
      toDate: sDate,
    });
    
    this.validateExportReport(sFDate, sDate);
    //console.log("setToDate(e)..end");
  }
  validateExportReport(from, to) {
    const now = new Date();
    const fD = new Date(from);
    const tD = new Date(to);
    const diffDate = fD-tD;
    //console.log('fD=', fD);
    //console.log('fD-tD=', ftD);
    
    if((fD < now
     && tD < now)
     && fD <= tD) {
      if (diffDate <= -2678400000) {
        this.setState({
          isCanExport: false,
          exportMsg: 'You can select a date range of 1 month.',
        });
      } else {
        this.setState({
          isCanExport: true,
          exportMsg: '',
        });
      }
    } else {
      this.setState({
        isCanExport: false,
        exportMsg: 'Your date range is invalid.',
      });
    }
  }
  renderExportReport() {
    const { fromDate, toDate, isCanExport, exportMsg } = this.state;
    
    return (
      <View style={{padding: 10, margin: 10, borderRadius: 5, borderWidth: 1, borderColor: '#666666'}}>
        <Text style={styles.head1Text}>Ticketing Daily Report Export</Text>
        <View style={{ padding: 5, }}>
          <Text style={styles.textBold}>From Date Of Payment:</Text>
          <DatePicker value={fromDate} onChange={(e:any) => { this.setFromDate(e); }} />
        </View>
        <View style={{ padding: 5, }}>
          <Text style={styles.textBold}>To Date Of Payment:</Text>
          <DatePicker value={toDate} onChange={(e:any) => { this.setToDate(e); }} />
        </View>
        <View>
          <Text style={styles.errorText}>{exportMsg}</Text>
        </View>
        <View style={{ alignItems: 'center', justifyContent: 'center', paddingTop: 10, }}>
          <View style={{ width: 200, }}>
          <Button title='Export Report' color={'#cccc00'} onPress={() => { this.exportReport() }} disabled={!isCanExport} />
          </View>
        </View>
      </View>
    );
  }
}

function DatePicker({ value, onChange }) {  
  return React.createElement('input', {
    type: 'date',
    value: value,
    onInput: onChange,
    style: styles.inputDate,    
  })
}

export default HomeScreen;