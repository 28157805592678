import React from 'react';
import { Button, StyleSheet, Text, TextInput, View, ScrollView, Alert } from 'react-native';
import { API_ENDPOINT, GetRequest, GetCurrentUser } from '../../services/dataservice';
import SplashScreen from '../splash';
import { styles } from '../../styles';
import ModalDialog from '../modal';
import ReportComponent from '../report';

class RealTimeScreen extends React.Component {
  modalDialogRef;

  constructor(props:any) {
    let date = new Date();
    let sDate = date.toISOString().split("T")[0]

    super(props);
    this.state = {
      name: "RealTimeScreen",
      isLoading: true,
      datas: [],
      pnrCode: '',
      reportDate: sDate,
      bookingPcc: '',
      issuingPccs: '',
      errorMsg: '',
      isCanPress: false,
      isShowPnrCode: true,
      isShowPcc: false,
      isPccCanPress: false,
    };

    this.modalDialogRef = React.createRef();

    GetCurrentUser().then((data) => {
      this.setState({
        bookingPcc: data.ownerPcc,
        issuingPccs: data.canExportPcc
      });
    });
  }
  render() {
    //console.log('render()..');
    if (this.state.isLoading == true) {
      return <SplashScreen />
    }

    return (
    <>
      <View>
        <Text style={styles.headerText}>Real Time Reports</Text>
        {this.renderBody()}
        <ModalDialog ref={this.modalDialogRef} />
      </View>
      {this.renderReport()}
    </>
    );
  }
  componentDidMount () {
    //console.log('componentDidMount()..');
    //this.fetchSettingData();
    this.loaded();
  }
  loading() {
    this.setState({
      isLoading: true,
    });
  }
  loaded() {
    this.setState({
      isLoading: false,
    });
  }
  showPnrCodeView() {
    this.setState({
      isShowPnrCode: true,
      isShowPcc: false,
    });
  }
  showPccView() {
    this.setState({
      isShowPnrCode: false,
      isShowPcc: true,
    });
  }
  setPnrCode(e:any) {
    let pnrCode:string = e.target.value.replace(/[^a-z-A-Z]/g, "");
    let isCanPress = false;
    let errorMsg = '';

    if (pnrCode !== '' 
     && pnrCode !== undefined
     && pnrCode.length >= 6) {
      isCanPress = true;
    } else {
      errorMsg: 'Please, enter PNR Code.';
    }

    this.setState({
      pnrCode: pnrCode.toUpperCase(),
      errorMsg: errorMsg,
      isCanPress: isCanPress,
    });
  }
  realTimeReportPnrCode() {
    const { pnrCode, errorMsg } = this.state;
    try {
      let datas:any = [];

      this.loading();
      GetRequest(`api/realtimereports/${pnrCode}`)
      .then((jsonData:any) => {
        //console.log("jsonData:", jsonData);
        if (jsonData.message === 'ok') {
          datas = jsonData.datas;
        } else {
          this.setState({
            errorMsg: jsonData.message,
          });
        }
      })
      .catch((e) => {
        console.log("error:", e);
        this.setState({
          errorMsg: e,
        });
      })
      .finally(async () => {
        this.setState({
          datas: datas,
        });
        //console.log("datas:", datas);
        this.loaded();
      });
    } catch(e) {
      console.error("stack trace follow:", e);
      alert('Error: ' + e);
    } finally {
    }

  }
  realTimeReportBookingPcc() {
    const { reportDate, bookingPcc, issuingPccs, errorMsg } = this.state;
    
    try {
      //console.log('Dump: reportDate=', reportDate);
      //console.log('Dump: bookingPcc=', bookingPcc);
      //console.log('Dump: issuingPccs=', issuingPccs);
      //console.log('Dump: errorMsg=', errorMsg);

      let datas:any = [];

      let queryString = `ReportDate=${reportDate}`;
      if (bookingPcc !== '' && bookingPcc !== undefined && bookingPcc !== null) {
        queryString = `${queryString}&BookingPcc=${bookingPcc}`;
      }
      if (issuingPccs !== '' && issuingPccs !== undefined && issuingPccs !== null) {
        let pccs:string[] = issuingPccs
          .replace(/\s/g, "")
          .toUpperCase()
          .split(',');
        //console.log('Dump: pccs=', pccs);
        let pccsString = '';
        pccs.map((p) => {
          pccsString = `${pccsString}&IssuingPccs=${p}`;
        });
        //console.log('Dump: pccsString=', pccsString);
        queryString = `${queryString}${pccsString}`;
      }

      //console.log('Dump: queryString=', queryString);
      
      this.loading();
      GetRequest(`api/realtimereports?${queryString}`)
      .then((jsonData:any) => {
        //console.log("jsonData:", jsonData);
        if (jsonData.message === 'ok') {
          datas = jsonData.datas;
        } else {
          this.setState({
            errorMsg: jsonData.message,
          });
        }
      })
      .catch((e) => {
        console.log("error:", e);
        this.setState({
          errorMsg: e,
        });
      })
      .finally(async () => {
        this.setState({
          datas: datas,
        });
        //console.log("datas:", datas);
        this.loaded();
      });
    } catch(e) {
      console.error("stack trace follow:", e);
      alert('Error: ' + e);
    } finally {
    }
  }
  renderBody() {
    const { isShowPcc, isShowPnrCode } = this.state;

    return (
      <>      
      <View style={styles.container}>
        <View style={styles.buttonContainers}>
          <View style={styles.buttonContainer}>
            <Button 
              title='PNR'
              color={ isShowPnrCode ? '#4444cc' : '#ccccff'}
              onPress={() => { this.showPnrCodeView() }}
            />
          </View>
          <View style={styles.buttonContainer}>
            <Button
              title='PCC'
              color={ isShowPcc ? '#4444cc' : '#ccccff'}
              onPress={() => { this.showPccView() }}
            />
          </View>
        </View>
        {isShowPnrCode 
          ? this.renderPnrCodeForm()
          : isShowPcc
            ? this.renderPccForm()
            : <></>}
      </View>
    </>
    );
  }
  renderPnrCodeForm() {
    const { pnrCode, errorMsg, isCanPress } = this.state;
    return (
      <View style={{padding: 10, margin: 10, borderRadius: 5, borderWidth: 1, borderColor: '#666666'}}>
        <View style={{ padding: 5, }}>
          <Text style={styles.textBold}>PNR Code:</Text>
          <TextInput 
            style={styles.input} 
            value={pnrCode} 
            onChange={(e:any) => { this.setPnrCode(e); }}
            placeholder='PNR Code' />
        </View>
        <View>
          <Text style={styles.errorText}>{errorMsg}</Text>
        </View>
        <View style={{ alignItems: 'center', justifyContent: 'center', paddingTop: 10, }}>
          <View style={{ width: 200, }}>
          <Button title='Query' color={'#cccc00'} onPress={() => { this.realTimeReportPnrCode() }} disabled={!isCanPress} />
          </View>
        </View>
      </View>);
  }
  renderPccForm() {
    const { reportDate, bookingPcc, issuingPccs, errorMsg, isPccCanPress } = this.state;
    
    let canQuery = isPccCanPress || (bookingPcc !== '');

    return (
      <View style={{padding: 10, margin: 10, borderRadius: 5, borderWidth: 1, borderColor: '#666666'}}>
        <View style={{ padding: 5, }}>
          <Text style={styles.textBold}>Report Date:</Text>
          <DatePicker value={reportDate} onChange={(e:any) => { this.setReportDate(e); }} />
        </View>
        <View style={{ padding: 5, }}>
          <Text style={styles.textBold}>Booking PCC:</Text>
          <TextInput 
            style={styles.input} 
            value={bookingPcc} 
            onChange={(e:any) => { this.setBookingPcc(e); }}
            placeholder='Booking PCC' />
        </View>
        <View style={{ padding: 5, }}>
          <Text style={styles.textBold}>Issuing PCCs:</Text>
          <TextInput 
            style={styles.input}
            value={issuingPccs}
            onChange={(e:any) => { this.setIssuingPccs(e); }}
             multiline={true}
            numberOfLines={3}
            placeholder='Issuing PCCs' />
        </View>
        <View>
          <Text style={styles.errorText}>{errorMsg}</Text>
        </View>
        <View style={{ alignItems: 'center', justifyContent: 'center', paddingTop: 10, }}>
          <View style={{ width: 200, }}>
          <Button title='Query' color={'#cccc00'} onPress={() => { this.realTimeReportBookingPcc() }} disabled={!canQuery} />
          </View>
        </View>
      </View>);
  }
  setReportDate(e:any) {
    let now = new Date();
    let date = new Date(e.target.value);

    if (date > now) {
      date = now;
    }

    const sDate = date.toISOString().split("T")[0]
    this.setState({
      reportDate: sDate,
      isPccCanPress: true,
    });
  }
  setBookingPcc(e:any) {
    let bookingPcc:string = e.target.value.replace(/[^a-z-A-Z-0-9]/g, "");
    this.setState({
      bookingPcc: bookingPcc,
      isPccCanPress: true,
    });
  }
  setIssuingPccs(e:any) {
    this.setState({
      issuingPccs: e.target.value,
      isPccCanPress: true,
    });
  }
  renderReport() {
    return (
    <View style={{width: 3600}}>
      <ReportComponent datas={this.state.datas} />
    </View>);
  }
  showMsg(msg:string): void {
    //console.log('showMsg()..called');
    //console.log('msg=', msg);

    this.modalDialogRef.current.showDetail("SET_MODAL", "info", msg);

    //console.log('showMsg()..end');
  }
}

function DatePicker({ value, onChange }) {
  return React.createElement('input', {
    type: 'date',
    value: value,
    onInput: onChange,
    style: styles.inputDate,
  })
}

export default RealTimeScreen;