import React from "react";
import { Text, View, StyleSheet, TextInput, Button } from "react-native";
import { RadioButton } from "react-native-paper";
import SplashScreen from "../splash";
import ModalDialog from '../modal';
import { GetCurrentUser, PutRequest } from "../../services/dataservice";
import { styles } from "../../styles";

class ProfileScreen extends React.Component {
  modalDialogRef = React.createRef();

  constructor(props:any) {
    super(props);
    this.state = {
        isLoading: true,
        isUnChanged: true,
        username: '',
        userRole: '',
        newUserRole: '',
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
        confirmPasswordMessage: '',
        isDone: false,
        canExportPcc: '',
        ownerPcc: '',
        agentName: '',
    };
  }
  loading() {
    this.setState({
      isLoading: true,
    });
  }
  loaded() {
    this.setState({
      isLoading: false,
    });
  }
  showMsg(msg:string): void {
    //console.log('showMsg()..called');
    //console.log('msg=', msg);
    //console.log('this.modalDialogRef=', this.modalDialogRef);
    //console.log('this.modalDialogRef.current=', this.modalDialogRef.current);

    this.modalDialogRef.current.showDetail("SET_MODAL", "info", msg);

    //console.log('showMsg()..end');
  }
  showError(msg:string): void {
    //console.log('showError()..called');
    //console.log('msg=', msg);
    //console.log('this.modalDialogRef=',this.modalDialogRef);
    //console.log('this.modalDialogRef.current=',this.modalDialogRef.current);

    this.modalDialogRef.current.showDetail("SET_MODAL", "error", msg);

    //console.log('showMsg()..end');
  }
  renderMySelfChange() {
    const { username, userRole, isUnChanged, isDone, canExportPcc, ownerPcc, agentName } = this.state;
    
    if(isDone) {
      return (<View>
        <Text>Change password done.</Text>
        <Button 
          title="Back"
          onPress={() => { this.props.navigation.goBack(); }} 
        />
      </View>);
    }

    return (
      <View>
        <form>
        <View style={{paddingTop: 10, paddingBottom: 10,}}>
          <Text style={styles.head2Text}>Email: {username}</Text>
          <Text style={styles.head2Text}>Role: {userRole}</Text>
          <Text style={styles.head2Text}>Agent Name: {agentName}</Text>
          <Text style={styles.head2Text}>Owner PCC: {ownerPcc}</Text>
          <Text style={styles.head2Text}>Issuing PCC: {canExportPcc}</Text>
        </View>        
        <View>
          <Text style={styles.textBold}>Old Password:</Text>
          <TextInput 
            style={styles.input}
            placeholder='Old Password'
            value={this.state.oldPassword}        
            onChangeText={(val) => {
              this.setOldPassword(val);
            }}
            secureTextEntry
          />
        </View>
        <View>
          <Text style={styles.textBold}>New Password:</Text>
          <TextInput 
            style={styles.input}
            placeholder='New Password'
            value={this.state.newPassword}
            onChangeText={(val) => {
              this.setNewPassword(val);
            }}
            secureTextEntry
          />
        </View>
        <View>
          <Text style={styles.textBold}>Confirm New Password:</Text>
          <TextInput 
            style={styles.input}
            placeholder='Confirm New Password'
            value={this.state.confirmNewPassword}
            onChangeText={(val) => {
              this.setConfirmNewPassword(val);
            }}
            secureTextEntry
          />
          {( this.state.confirmPasswordMessage != ''
            ? <Text style={styles.errorText}>{this.state.confirmPasswordMessage}</Text>
            : <></> )}
        </View>
        <View style={{flex: 1, flexDirection: 'row'}}>
          <View style={{flex: 1, width: 120, padding: 10,}}>
            <Button 
              title="Reset"
              onPress={() => {this.resetData();}}
            />
          </View>
          <View style={{flex: 1, width: 120, padding: 10,}}>
          <Button 
              title="Change"
              disabled={isUnChanged}
              onPress={() => {this.updateDataToHost();}}
            />
          </View>
        </View>
        </form>
      </View>
    );
  }
  render() {
    if(this.state.isLoading) {
      return <SplashScreen />
    }

    return (
      <View style={styles.container}>
        <Text style={styles.headerText}>Your Profile</Text>
        {this.renderMySelfChange()}
        {/* <Button title="Test" onPress={() => {this.showMsg("Hello World!"); this.showError("Error"); }} /> */}
        <ModalDialog ref={this.modalDialogRef} />
      </View>
    );
  }
  setUsername(val) {
    this.setState({
        username: val,
        isUnChanged: false,
    });
  }
  setOldPassword(val) {
    this.setState({
        oldPassword: val,
    });

    this.checkData(val, this.state.newPassword, this.state.confirmNewPassword);
  }
  setNewPassword(val) {
    this.setState({
        newPassword: val,
    });

    this.checkData(this.state.oldPassword, val, this.state.confirmNewPassword);
  }
  setConfirmNewPassword(val) {
    this.setState({
        confirmNewPassword: val,
    });

    this.checkData(this.state.oldPassword, this.state.newPassword, val);
  }
  checkData(oPwd:string, nPwd:string, cPwd:string) {
    if (oPwd === '' 
     || nPwd === '' 
     || cPwd === '') {
      this.setState({
        confirmPasswordMessage: 'Please, enter password data.',
        isUnChanged: true,
      });
    } else if (nPwd !== cPwd) {
      this.setState({
        confirmPasswordMessage: 'New Password and Confirm New Password not match.',
        isUnChanged: true,
      });
    } else {
      this.setState({
        confirmPasswordMessage: '',
        isUnChanged: false,
      });
    }
  }
  resetData() {
    //this.loading();
    GetCurrentUser().then((data) => {
      //console.log("data=", data);
      this.setState({
        isUnChanged: true,
        username: data.username,
        userRole: data.role,
        newUserRole: data.role,
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
        confirmPasswordMessage: '',
        canExportPcc: data.canExportPcc,
        ownerPcc: data.ownerPcc,
        agentName: data.agentName,
      })
    })
    .finally(() => {
      //this.loaded();
    });
  }
  componentDidMount() {
    this.resetData();
    this.loaded();
  }
  updateDataToHost() {
    const { username, oldPassword, newPassword, confirmNewPassword } = this.state;

    if (oldPassword === '') {
      this.showError('Please, enter old password.');
    } else if (newPassword === '') {
      this.showError('Please, enter new password.');
    } else if (confirmNewPassword === '') {
      this.showError('Please, enter confirm new password.');
    } else if (newPassword !== confirmNewPassword) {
      this.showError('New Password and Confirm New password not match.');
    }
    
    const self = this.modalDialogRef.current;    
    //console.log("this.modalDialogRef=", this.modalDialogRef);
    //console.log("self=", self);
    PutRequest('api/change/password', { email: username, oldPassword: oldPassword, newPassword: newPassword })
    .then((jsonData) => {
      //console.log('jsonData=', jsonData);

      if (jsonData.ok === false) {
        this.showError('Change password fail. Old password is invalid.');
      }
      else {
        
        this.resetData();
        this.setState({ isDone: true });
        this.showMsg('Change password done.');
        // setTimeout(() => {
        //   this.props.navigation.goBack();
        // }, (4000));
      }
    })
    .catch((e) => {
      console.error(e);
      alert(e);
    })
    .finally(() => {
      
    });
  }
}

export default ProfileScreen;