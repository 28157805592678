import { Image } from "react-native";

function LogoTitle() {
    return(
        <Image 
            style={{ width: 300, height: 60 }}
            source={require('../../assets/cropped-logo.png')}
        />
    );
}

export default LogoTitle;