import { SafeAreaProvider } from 'react-native-safe-area-context';
import { createNativeStackNavigator, NavigatorScreenParams } from '@react-navigation/native-stack';
import { NavigationContainer } from '@react-navigation/native';
import { StatusBar } from 'expo-status-bar';
import * as React from 'react';
import { StyleSheet, Text, View, Button, Image, Alert } from 'react-native';
import SplashScreen from './screens/splash';
import { AuthContext } from './services/authcontext';
import jwt_decode from 'jwt-decode';
import AsyncStorage from '@react-native-async-storage/async-storage';
// screen
import HomeScreen from './screens/home';
import LoginScreen from './screens/login';
import SettingsScreen from './screens/settings';
import CalendarComponent from './screens/calendar';
import LogoTitle from './screens/logo';
import ProfileScreen from './screens/profile';
import UsersScreen from './screens/users';
import { PostRequest, getToken } from './services/dataservice';
import RealTimeScreen from './screens/realtime';

export default function App() {
    var clentId:any;

    const [state, dispatch] = React.useReducer(
        (prevState, action) => {
            switch (action.type) {
                case 'RESTORE_TOKEN':
                    return {
                        ...prevState,
                        userToken: action.token,
                        isLoading: false,
                    };
                case 'SIGN_IN':
                    return {
                        ...prevState,
                        isSignout: false,
                        userToken: action.token,
                        isLoading: false,
                    };
                case 'SIGN_OUT':
                    return {
                        ...prevState,
                        isSignout: true,
                        userToken: null,
                        isLoading: false,
                    };
            }
        },
        {
            isLoading: true,
            isSignout: false,
            userToken: null,
        }
    );

    React.useEffect(() => {
        // Fetch the token from storage then navigate to our appropriate place
        const bootstrapAsync = async () => {
            let userToken;

            try {
                // Restore token stored in `SecureStore` or any other encrypted storage
                userToken = await getToken();
            } catch (e) {
                // Restoring token failed
            }

            // After restoring token, we may need to validate it in production apps

            // This will switch to the App screen or Auth screen and this loading
            // screen will be unmounted and thrown away.
            dispatch({ type: 'RESTORE_TOKEN', token: userToken });
        };

        bootstrapAsync();
    }, []);

    const authContext = React.useMemo(
        () => ({
            signIn: async (data, showMsg) => {
                //console.log("authContext.signIn(data)..");
                //console.log(data);
                // In a production app, we need to send some data (usually username, password) to server and get a token
                // We will also need to handle errors if sign in failed
                // After getting token, we need to persist the token using `SecureStore` or any other encrypted storage
                // In the example, we'll use a dummy token
                PostRequest('api/login', data)
                .then((jsonData) => {
                    //console.log('jsonData=', jsonData);
                    if (jsonData.ok === false) {
                      // error
                      //console.log("showMsg=", showMsg);
                      showMsg("SET_MODAL", "error", 'Username or password invalid.');
                    } else {
                      //console.log(jsonData);
                      AsyncStorage.setItem('userToken', jsonData.accessToken);
                      AsyncStorage.setItem('refreshToken', jsonData.refreshToken);
                      dispatch({ type: 'SIGN_IN', token: jsonData.accessToken });
                    }
                })
                .catch((e) => {
                    console.error('e=', e);
                    showMsg("SET_MODAL", "error", e);
                })
            },
            signOut: () => {
                //console.log('authContext.signOut()..called');
                //console.log('state=', state);
                clearTimeout(clentId);
                AsyncStorage.setItem('userToken', '');
                AsyncStorage.setItem('refreshToken', '');
                dispatch({ type: 'SIGN_OUT' });
                //console.log('state=', state);
                window.location.href = '/';
                //console.log('authContext.signOut()..end');
            },
            // signUp: async (data) => {
            //     // In a production app, we need to send user data to server and get a token
            //     // We will also need to handle errors if sign up failed
            //     // After getting token, we need to persist the token using `SecureStore` or any other encrypted storage
            //     // In the example, we'll use a dummy token

            //     dispatch({ type: 'SIGN_IN', token: 'dummy-auth-token' });
            // },
        }),
        []
    );

    if (state.isLoading) {
        return (<SplashScreen />);
    }
    const Stack = createNativeStackNavigator();
    
    //console.log("authContext=");
    //console.log(authContext);

    if (state.userToken != null 
      && state.userToken != undefined
      && state.userToken != 'undefined'
      && state.userToken != '') {
        
        const jwt = jwt_decode(state.userToken);
        //console.log("jwt=", jwt);
        if (jwt != undefined) {
            const now = Date.now() / 1000;
            const diffTime = jwt.exp - now;
            
            //console.log("diffTime=", diffTime);

            if (diffTime > 0) {
              //console.log('set jwt timeout');
              clentId = setTimeout(() => {
                authContext.signOut();
              }, diffTime * 1000);
            }

            if (jwt.exp < now) {
                state.userToken = null;
                authContext.signOut();
            }            
        }
    }

    // console.log('state=');
    // console.log(state);
    // console.log(state.userToken !== undefined );
    // console.log(state.userToken !== null);
    // console.log(state.userToken !== "");

    const isSignedIn = state.userToken !== undefined 
        && state.userToken !== null
        && state.userToken !== "";

    //console.log('isSignedIn=', isSignedIn);

    return (
        <SafeAreaProvider>
            <AuthContext.Provider value={authContext}>
            <NavigationContainer>
            <Stack.Navigator>
                {!isSignedIn ? (
                    <Stack.Screen name="Login" component={LoginScreen} options = {{ 
                      headerTitle: (props) => <LogoTitle {...props} />, 
                      title: 'Ticketing Report' 
                    }}/>
                )
                : 
                (
                  <>
                    <Stack.Screen
                      name="Home"
                      component={HomeScreen} 
                      options={{
                      headerTitle: (props) => (
                        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', }}>
                          <Text style={{fontWeight: 'bold', fontSize: 24,}}>Ticketing Daily Report System</Text>
                        </View>
                      ),
                      headerTitleAlign: "center",
                      headerLeft: (props) => (<LogoTitle {...props} />),
                      headerRight: () => (
                        <View style={{marginRight: 5}} ><Button 
                          onPress={() => { authContext.signOut(); }}
                          title="Logout"
                          color='#cc0000'
                        /></View>
                      ),
                    }}/>
                    {/* <Stack.Screen name="Login" component={LoginScreen} options = {{ 
                      headerTitle: (props) => <LogoTitle {...props} />, 
                      title: 'Ticketing Report' 
                    }}/> */}
                  </>
                )}
                <Stack.Screen name="Settings" component={SettingsScreen} />
                <Stack.Screen name="Reports" component={CalendarComponent} />
                <Stack.Screen name="Profile" component={ProfileScreen} />
                <Stack.Screen name="Users" component={UsersScreen} />
                <Stack.Screen name="RealTime" component={RealTimeScreen} />
            </Stack.Navigator>    
            </NavigationContainer>
            </AuthContext.Provider>
            <StatusBar />
        </SafeAreaProvider>
    );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
