import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  // LoginScreen
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonContainers: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: 300,
  },
  buttonContainer: {
    flex: 1,
    padding: 5,
  },
  textBold: {
    fontWeight: 'bold',
  },
  textCenter: {
    textAlign: 'center',
  },
  input: {
    width: 300,
    // height: 40,
    margin: 12,
    borderWidth: 1,
    padding: 10,
  },
  inputMultiline: {
    width: 300,
    height: 100,
    margin: 12,
    borderWidth: 1,
    padding: 10,
  },
  inputDate: {
    //width: 300,
    // height: 40,
    margin: 12,
    borderWidth: 1,
    padding: 10,
  },
  headerText: {
    fontSize: 24,
    fontWeight: 'bold',
    alignItems: 'flex-start',
  },
  errorText: {
    color: '#FF0000',
  },
  // HomeScreen
  containerHome: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 5,
  },
  // ProfileScreen
  radioInput: {
    flexDirection: 'row', 
    alignItems: 'center',
  },
  // UserScreen
  containerUser: {
    flex: 1,
    backgroundColor: '#fff',    
    alignItems: 'center',
  },
  gridBody: {
    backgroundColor: '#000000',
    width: '100%',
    padding: 1,
    margin: 5,
  },
  gridHeadRow: {
    padding: 5,
    borderTopWidth: 1,
    borderTopColor: '#000000',
    borderBottomWidth: 1,
    borderBottomColor: '#000000',
    color: '#FFFFFF',
    backgroundColor: '#000000',
    //margin: 24, fontSize: 18, fontWeight: 'bold', textAlign: 'center'
  },
  gridRow: {
    borderBottomWidth: 1,
    borderBottomColor: '#000000',
  },
  gridOddRow: {
    borderBottomWidth: 1,
    borderBottomColor: '#000000',
    backgroundColor: '#FFFFFF',
    padding: 5,
  },
  gridEvenRow: {
    borderBottomWidth: 1,
    borderBottomColor: '#000000',    
    backgroundColor: '#EEEEEE',
    padding: 5,
  },
  gridTotalRow: {
    borderBottomWidth: 1,
    borderBottomColor: '#000000',    
    backgroundColor: '#BBBBBB',
    padding: 5,
  },
  gridHeaderText: {
    fontWeight: 'bold',
    fontSize: 16,
    color: '#FFFFFF',
  },
  // Modal
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2
  },
  buttonOpen: {
    backgroundColor: "#F194FF",
  },
  buttonClose: {
    backgroundColor: "#2196F3",
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center"
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center"
  },
  // Calendar
  calendar: {
    marginBottom: 10
  },
  switchContainer: {
    flexDirection: 'row',
    margin: 10,
    alignItems: 'center'
  },
  switchText: {
    margin: 10,
    fontSize: 16
  },
  head1Text: {
    fontWeight: 'bold',
    fontSize: 18,
    textAlign: 'center',
    padding: 10,
  },
  head2Text: {
    fontWeight: 'bold',
    fontSize: 16,
  },
  head3Text: {
    fontWeight: 'bold',
    textAlign: 'center',
  },
  disabledText: {
    color: 'grey'
  },
  defaultText: {
    color: 'purple'
  },
  customCalendar: {
    height: 250,
    borderBottomWidth: 1,
    borderBottomColor: 'lightgrey'
  },
  customDay: {
    textAlign: 'center'
  },
  customHeader: {
    backgroundColor: '#FCC',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginHorizontal: -4,
    padding: 8
  },
  customTitleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10
  },
  customTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#00BBF2'
  },
  lottie: {
    width: 100,
    height: 100
  },
  textVersion: {
    fontSize: 10,
    color: 'gray',
    flexShrink: 1,
  },
  modalContainer: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    //alignItems: 'flex-start' // if you want to fill rows left to right
    alignItems: 'center',
  },
  modalItem: {
    width: '50%' // is 50% of container width
  },
  modalInput: {
    //width: 300,
    // height: 40,
    margin: 12,
    borderWidth: 1,
    padding: 10,
  },
});

export { styles };