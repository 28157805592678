import React, { useState, Fragment, useCallback, useMemo, useRef } from 'react';
import { StyleSheet, View, Text, Linking, Button, Alert } from 'react-native';
import { Col, Row, Grid } from "react-native-easy-grid";
import { API_ENDPOINT, GetRequest, PostRequest } from '../../services/dataservice';
import { CalendarUtils } from 'react-native-calendars';
import { AuthContext } from '../../services/authcontext';
import SplashScreen from '../splash';
import { styles } from '../../styles';
import ReportComponent from '../report';

class CalendarComponent extends React.Component {
  //const onDayPress = React.useCallback((day:any) => {
  //  setSelected(day.dateString);
  //}, []);  
  //const [selected, setSelected] = React.useState(getDate(-1));
  constructor(props:any) {
    //console.log("constructor()..");
    super(props);    
    this.state = {
      name: "CalendarComponent",
      isLoading: true,
      calendarData: {
        backButton: 0,
        nextButton: 0,
        minDate: '2022-01-01',
        maxDate: '2022-01-31',
        reportDates: [],
      },
      selectedDate: null,
      datas: [],
      monthItems: {},
      years: [],
      selectedYear: null,
      months: [],
      selectedMonth: null,
      days: [],
      selectedDay: null,
    };
  }
  static contextType = AuthContext;
  getDate(count: number) {
    const date = new Date();
    const newDate = date.setDate(date.getDate() + count);
    return CalendarUtils.getCalendarDateString(newDate);
  }
  render() {
    //console.log('render()..');
    if (this.state.isLoading == true) {
      return <SplashScreen />
    }

    return (
      <>
        <View style={{flex: 1, flexDirection: "row"}}>
          <View style={{ width: 150, borderRightWidth: 1, padding: 0, }}>
            <Text style={styles.head3Text}>Date Of Payment</Text>
            <View style={{ padding: 3 }}>
              {this.renderYears()}
            </View>
          </View>
          <View>
            {this.renderReportBody()}
          </View>
        </View>
      </>
    );
  }
  componentDidMount () {
    //console.log('componentDidMount()..');
    this.fetchCalendarData();
  }
  loading() {
    this.setState({
      isLoading: true,
    });
  }
  loaded() {
    this.setState({
      isLoading: false,
    });
  }
  fetchCalendarData() {    
    //console.log('fetchCalendarData()..');
    this.loading();
    GetRequest('api/calendar/years').then((jsonData) => {
      this.setState({
        years: jsonData,
      });      
    }).finally (() => {
      this.loaded();
    });
  }
  fetchTicketSummaryReportsData(dateString:any) {
    //const dateString = '2022-12-28';
    //console.log("fetchTicketSummaryReportsData()..");
    // https://cds-kh.travelpro.in.th/api/ticketsummaryreports/2022-12-29
    GetRequest('api/ticketsummaryreports/' + dateString)
    .then((jsonData) => {      
      //console.log(jsonData);
      //console.log(this);
      this.setState({
        ticketSummaryReports: jsonData,
      });
    });
  }
  // renderBackButton() {
  //   if(this.state.calendarData.backButton < 0) {
  //     return (
  //       <Button
  //         title='Previous'
  //         onPress={() => {
  //           var url = 'api/calendar/getcalendar/' + this.state.calendarData.backButton;
  //           GetRequest(url).then((jsonData) => {
  //             this.setState({
  //               calendarData: jsonData,
  //             });      
  //           });
  //         }}
  //       />
  //     );
  //   }

  //   return <Button title='Previous' disabled={true} />
  // }
  // renderNextButton() {
  //   if (this.state.calendarData.nextButton == 0 
  //     && this.state.calendarData.backButton < 0) {
  //     // รายการปัจจุบัน
  //     return <Button title='Next' disabled={true} />
  //   }
  //   else if(this.state.calendarData.nextButton <= 0) {
  //     return (
  //       <Button
  //         title='Next'
  //         onPress={() => {
  //           var url = 'api/calendar/getcalendar/' + this.state.calendarData.nextButton;
  //           GetRequest(url).then((jsonData) => {
  //             this.setState({
  //               calendarData: jsonData,
  //             });      
  //           });
  //         }}
  //       />
  //     );
  //   }

  //   return <Button title='Next' disabled={true} />
  // }
  // renderReportButton() {
  //   let reports = this.state.calendarData.reportDates.filter((r) => { return r.isHaveData == true });
  //   return reports.map((r:any) => {
  //     console.log(r);
  //     if(r.isHaveData === true) {
  //       return(
  //       <Button 
  //         title={r.reportDate}
  //         onPress={() => {
  //           let sefe = this;
  //           this.loading();
  //           GetRequest('api/ticketsummaryreports/' + r.reportDate)
  //             .then((jsonData) => {
  //               sefe.setState({
  //                 datas: jsonData,
  //                 selectedDate: r.reportDate,
  //               });
  //             })
  //             .finally(() => {
  //               this.loaded();
  //             });
  //           }}
  //       />);
  //     } else if (r.isHaveData === false) {
  //       return(
  //         <Button 
  //           title={r.reportDate}
  //         />
  //       );
  //     } 

  //     return(
  //       <Text>-{r.reportDate}-</Text>
  //     );
  //   });
  // }
  // renderMonthDropDown() {
  //   return (
  //     <DropDownPicker
  //       items={[
  //         {label:'', value:''}
  //       ]}
  //       defaultIndex={0}
  //       containerStyle={{height: 40}}
  //       onChangeItem={item => console.log(item.label, item.value)}
  //     />
  //   );
  // }  
  renderReportBody(): React.ReactNode {
    //console.log('renderReportBody()..');
    //console.log(this.state.selectedDate);

    if (this.state.selectedDate == null
      || this.state.selectedDate === null) {
      return (<></>);
    }

    var no = 0;
    let url = API_ENDPOINT + "api/reportexports/" + this.state.selectedDate;

    const OpenURLButton = ({ reportDate, children }) => {
      const handlePress = useCallback(async () => {
        // get export token
        let exportToken = '';
        PostRequest('api/exporttokens', { forReportDate: reportDate })
        .then((jsonData) => {
          //console.log('jsonData=', jsonData);
          if (jsonData.ok === false 
           && jsonData.status === 401) {
            this.context.signOut();
          } else {
            exportToken = jsonData.exportToken;
          }          
        })
        .finally(async () => {
          if (exportToken !== '') {
            url = `${API_ENDPOINT}api/reportexports/${exportToken}/${reportDate}`;
            // Checking if the link is supported for links with custom URL scheme.
            const supported = await Linking.canOpenURL(url);
      
            if (supported) {
              // Opening the link with some app, if the URL scheme is "http" the web link should be opened
              // by some browser in the mobile
              await Linking.openURL(url);
            } else {
              alert(`Don't know how to open this URL: ${url}`);
            }
          }
        })
      }, [url]);
    
      return <Button color={'#cccc00'} title={children} onPress={handlePress} />;
    };

    return (
      <>
        <View style={{ width: 180, padding: 5, }}>
          <OpenURLButton reportDate={this.state.selectedDate}>{'Export ' + this.state.selectedDate}</OpenURLButton>
        </View>
        {/* <ScrollView showsVerticalScrollIndicator={true} showsHorizontalScrollIndicator={true}> */}
        <ReportComponent datas={this.state.datas} />
        {/* </ScrollView> */}
      </>
    );
  }
  // new UI
  renderYears(): React.ReactNode {
    //console.log('renderYears()..');
    //console.log(this.state.years);
    const { years, selectedYear } = this.state;
    
    if (this.state.isLoading 
      || years.length == 0) {
      return (<></>);
    }
    
    let no=0;
    return (
      <>
        {
        years.map((y) => {
          no++;
          return (
          <View key={no} style={{ padding: 2, }}>
            <Button title={y}
              onPress={() => {
                if (selectedYear != null
                  && selectedYear == y) {
                    this.setState({
                      selectedYear: null,
                    });
                } else {
                  this.loading();
                  GetRequest('api/calendar/months/' + y)
                  .then((jsonData) => {
                    this.setState({
                      months: jsonData,
                      selectedYear: y,
                    });
                  })
                  .finally(() => {
                    this.loaded();
                  });
                }
              }}
            />
            { ((selectedYear == y) ? this.renderMonths() : <></>) }
          </View>);
        })
        }
      </>
    );
  }
  renderMonths() {
    //console.log('renderMonths()');
    //console.log(this.state.selectedYear);
    //console.log(this.state.months);
    const { selectedYear, months, selectedMonth } = this.state;
    let no = 0;

    if (selectedYear == null) {
      return (<></>);
    }
    return (
      <>
        {
        months.map((m) => {
          no++;
          return (
            <View key={no} style={{ padding: 4, paddingTop: 1, }}>
            <Button               
              title={m} 
              onPress={() => {  
                if (selectedMonth != null
                  && selectedMonth == m) {
                    this.setState({
                      selectedMonth: null,
                    });
                } else {
                  this.loading();
                  GetRequest('api/calendar/days/' + m)
                  .then((jsonData) => {
                    this.setState({
                      days: jsonData,
                      selectedMonth: m,
                    });
                  })
                  .finally(() => {
                    this.loaded();
                  });
                }
              }}
            />
            { (selectedMonth == m) ? this.renderDays() : <></> }
            </View>);
        })
        }
      </>
    );
  }
  renderDays() {
    //console.log('renderDays()');
    //console.log(this.state.selectedMonth);
    //console.log(this.state.days);
    const { days, selectedMonth } = this.state;
    let no = 0;

    if (selectedMonth == null) {
      return (<></>);
    }
    return (
      <View style={{ padding: 4, paddingTop: 1, }}>
        {
          days.map((d) => {
            no++;
            return (
              <View key={no} style={{padding: 1,}}>
                <Button
                  title={d}
                  onPress={() => {
                    this.loading();
                    GetRequest('api/ticketsummaryreports/' + d)
                      .then((jsonData) => {
                        this.setState({
                          datas: jsonData,
                          selectedDate: d,
                        });
                      })
                      .finally(() => {
                        this.loaded();
                      });
                  }}
                />
            </View>);
          })
        }
      </View>
    );
  }
}

export default CalendarComponent;