import React from "react";
import { Text, View, StyleSheet, Button, Modal, Pressable, Alert, TextInput } from "react-native";
import { RadioButton } from "react-native-paper";
import { Col, Row, Grid } from "react-native-easy-grid";
import SplashScreen from "../splash";
import { GetRequest, PostRequest, PutRequest, ValidateEmail } from "../../services/dataservice";
import ModalDialog from '../modal';
import { styles } from "../../styles";

class UsersScreen extends React.Component {
  modalDialogRef;

  constructor(props:any) {
    super(props);
    this.state = {
      isLoading: true,
      users:[],
      addUserModalVisable: false,
      updateUserModalVisable: false,
      newUser: {
        email: '',
        password: '',
      },
      userId: 0,
      username: '',
      password: '',
      confirmPassword: '',
      userRole: 'Common',
      isUserChanged: false,
      confirmPasswordMessage: '',
      canExportPcc: '',
      ownerPcc: '',
      agentName: '',
    }
    this.modalDialogRef = React.createRef();
  }
  render(): React.ReactNode {
    const { isLoading } = this.state;

    if (isLoading) {
      return (<SplashScreen/>);
    }
    return (
      <View style={styles.containerUser}>
        <Text style={styles.headerText}>User's List</Text>
        {this.renderUser()}
        {this.renderAddUserModel()}
        {this.renderUpdateUserModel()}
        <ModalDialog ref={this.modalDialogRef} />
      </View>
    );
  }
  componentDidMount(): void {
      this.fetchUsers();
      //this.loaded();
  }
  loading() {
    this.setState({
      isLoading: true,
    });
  }
  loaded() {
    this.setState({
      isLoading: false,
    });
  }
  showMsg(msg:string): void {
    //console.log('showMsg()..called');
    //console.log('msg=', msg);
    //console.log('this.modalDialogRef=',this.modalDialogRef);
    //console.log('this.modalDialogRef.current=',this.modalDialogRef.current);

    this.modalDialogRef.current.showDetail("SET_MODAL", "info", msg);

    //console.log('showMsg()..end');
  }
  showError(msg:string): void {
    //console.log('showError()..called');
    //console.log('msg=', msg);
    //console.log('this.modalDialogRef=',this.modalDialogRef);
    //console.log('this.modalDialogRef.current=',this.modalDialogRef.current);

    this.modalDialogRef.current.showDetail("SET_MODAL", "error", msg);

    //console.log('showMsg()..end');
  }
  setUsername(val) {
    this.setState({
      username: val,
    })

    this.checkPwdMatch(val, this.state.password, this.state.confirmPassword);
  }
  checkPwdMatch(email, pwd, val) {
    //console.log('pwd=', pwd);
    //console.log('val=', val);
    //console.log("pwd === ''", pwd === '');

    if (email === '')
    {
      this.setState({
        confirmPasswordMessage: 'Please, enter Email.',
        isUserChanged: false,
      });
    } else if (!ValidateEmail(email)) {
      this.setState({
        confirmPasswordMessage: "Email is invalid",
        isUserChanged: false,
      });
    } else if (pwd != val) {
      this.setState({
        confirmPasswordMessage: 'Password and Confirm Password not match.',
        isUserChanged: false,
      });
    } else {
      this.setState({
        confirmPasswordMessage: '',
        isUserChanged: true,
      });
    }

    //console.log("this.modalDialogRef=", this.modalDialogRef);
  }
  setPassword(val) {
    this.setState({
      password: val,
    })
    if (this.state.confirmPassword !== '') {
      this.checkPwdMatch(this.state.username, val, this.state.confirmPassword);
    }
  }
  setConfirmPassword(val) {
    this.setState({
      confirmPassword: val,
    });
    this.checkPwdMatch(this.state.username, this.state.password, val);
  }
  setUserRole(val) {
    this.setState({ 
      userRole: val,
      isUserChanged: true, 
    });

    this.checkPwdMatch(this.state.username, this.state.password, this.state.confirmPassword);
  }
  setCanExportPcc(val:string) {
    this.setState({
      canExportPcc: val,
      isUserChanged: true,
    });
  }
  setOwnerPcc(val:string) {
    this.setState({
      ownerPcc: val,
      isUserChanged: true,
    });
  }
  setAgentName(val:string) {
    this.setState({
      agentName: val,
      isUserChanged: true,
    });
  }
  fetchUsers() {
    GetRequest('api/users')
    .then((jsonData) => {
      //console.log("jsonData=", jsonData);
      this.setState({
        users: jsonData,
      });
    })
    .finally(() => {
        this.loaded();
    });
  }
  renderUser() {
    const { users } = this.state;
    let no = 0;
    return (
      <View style={{width: '94%', }}>
        <Grid style={styles.gridBody}>
          <Row style={styles.gridHeadRow}>
            <Col><Text style={styles.gridHeaderText}>No.</Text></Col>
            <Col><Text style={styles.gridHeaderText}>Username (Email)</Text></Col>
            <Col><Text style={styles.gridHeaderText}>Roles</Text></Col>
            <Col><Text style={styles.gridHeaderText}>Owner PCC</Text></Col>
            <Col><Text style={styles.gridHeaderText}>Agent Name</Text></Col>
            <Col><Text style={styles.gridHeaderText}>Issuing PCC</Text></Col>
            <Col><Text style={styles.gridHeaderText}>Action</Text></Col>
          </Row>
          {users.map((user) => {
            no++;
            return (
            <Row style={no%2==0 ? styles.gridEvenRow : styles.gridOddRow}>
              <Col><Text>{no}</Text></Col>
              <Col><Text>{user.email}</Text></Col>
              <Col>{user.roles.map((role) => { return <Text>{role}</Text> })}</Col>
              <Col><Text>{user.ownerPcc}</Text></Col>
              <Col><Text>{user.agentName}</Text></Col>
              <Col><Text>{user.canExportPcc}</Text></Col>
              <Col>
                <Button 
                  title="Edit"
                  onPress={() => { this.editUser(user); }}
                  color="#cccc00"
                />
              </Col>
            </Row>
            );
          })}
        </Grid>
        <Button
          title="Add User"
          onPress={() => this.showAddUserModel()}
        />
      </View>
    );
  }
  editUser(user:any) {
    //console.log("user=", user);
    //this.showMsg('Edit user');
    this.setState({
      userId: user.id,
      username: user.email,
      userRole: user.roles[0],
      canExportPcc: user.canExportPcc,
      ownerPcc: user.ownerPcc,
      agentName: user.agentName,
    })
    this.showUpdateUserModel();
  }
  showAddUserModel() {
    this.setAddUserModalVisable(true);
  }
  closeAddUserModal() {
    this.setState({
      username: '',
      password: '',
      confirmPassword: '',
      isUserChanged: false,
      userRole: 'Common',
      confirmPasswordMessage: '',
      canExportPcc: '',
      agentName: '',
    })
    this.setAddUserModalVisable(false);
  }
  setAddUserModalVisable(visible:boolean) {
    this.setState({ addUserModalVisable: visible });
    //console.log("this.modalDialogRef=", this.modalDialogRef);
  };
  createUser() {
    const { username, password, canExportPcc, ownerPcc, agentName } = this.state;
    if(username === '' 
      || username === undefined 
      || username === null) {
      this.showError('Please, enter email.');
      return;
    }
    if(password === '' 
      || password === undefined 
      || password === null) {
      this.showError('Please, enter password.');
      return;
    }
    if(!ValidateEmail(username)) {
      this.showError('Error email is invalid.');
      return;
    }

    //console.log('this.modalDialogRef=',this.modalDialogRef);
    
    const self = this;
    PostRequest('api/users', { 
      email: username,
      password: password,
      canExportPcc: canExportPcc,
      ownerPcc: ownerPcc,
      agentName: agentName,
    }).then((jsonData) => {
      //console.log("jsonData=", jsonData);
      if (jsonData.ok === false) {
        // error
        self.showError('Add new user fail. Plase, check and try again.');
      }
      else {
        self.showMsg('Add new user done.');
        this.closeAddUserModal();
        this.fetchUsers();
      }
    })
    .catch((e) => {
      console.error(e);
      alert(e);
    })
    .finally(() => {
      
    });
  }
  renderAddUserModel() {
    const { addUserModalVisable, username, password, confirmPassword, userRole, isUserChanged, confirmPasswordMessage, canExportPcc, ownerPcc, agentName } = this.state;
    return (
      <View style={styles.centeredView}>
      <Modal
        animationType="slide"
        transparent={false}
        visible={addUserModalVisable}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          this.setAddUserModalVisable(!addUserModalVisable);
        }}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <Text style={[styles.modalText, styles.headerText]}>Add New User</Text>
            <Text style={{color: 'red'}}>case-sensitive</Text>
            <form>
            <View style={styles.modalContainer}>
            <View style={styles.modalItem}>
              <Text style={styles.textBold}>Email:</Text>
              <TextInput 
                style={styles.modalInput}
                placeholder='Email'
                value={username}
                onChangeText={(val) => {
                  this.setUsername(val);
                }}                
              />
            </View>
            <View style={styles.modalItem}>
              <Text style={styles.textBold}>User Role:</Text>
              <View style={styles.radioInput}>
                  <RadioButton
                  value="Common"
                  status={ userRole === 'Common' ? 'checked' : 'unchecked'}
                  onPress={() => { this.setUserRole('Common'); }}
                  />
                  <Text>Common Role</Text>
              </View>
            </View>
            <View style={styles.modalItem}>
              <Text style={styles.textBold}>Password:</Text>
              <TextInput 
                style={styles.modalInput}
                placeholder='Password'
                value={password}
                onChangeText={(val) => {
                  this.setPassword(val);
                }}
                secureTextEntry
              />
            </View>
            <View style={styles.modalItem}>
              <Text style={styles.textBold}>Confirm Password:</Text>
              <TextInput 
                style={styles.modalInput}
                placeholder='Confirm Password'
                value={confirmPassword}
                onChangeText={(val) => {
                  this.setConfirmPassword(val);
                }}
                secureTextEntry
              />
              {( confirmPasswordMessage != '' 
                ? <Text style={styles.errorText}>{confirmPasswordMessage}</Text>
                : <></> )}
            </View>
            <View style={styles.modalItem}>
              <Text style={styles.textBold}>Agent Name:</Text>
              <TextInput
                style={styles.modalInput}
                placeholder='Agent Name'
                value={agentName}
                onChangeText={(val) => {
                  this.setAgentName(val);
                }}
                maxLength={80}
              />
            </View>
            <View style={styles.modalItem}>
              <Text style={styles.textBold}>Owner PCC: (* = all PCCs)</Text>
              <TextInput
                style={styles.modalInput}
                placeholder='Owner PCC'
                value={ownerPcc}
                onChangeText={(val) => {
                  this.setOwnerPcc(val);
                }}
                maxLength={4}
              />
            </View>
            <View style={styles.modalItem}>
              <Text style={styles.textBold}>Issuing PCC:</Text>
              <TextInput
                style={styles.modalInput}
                placeholder='Issuing PCC'
                value={canExportPcc}
                onChangeText={(val) => {
                  this.setCanExportPcc(val);
                }}
                multiline={true}
                numberOfLines={3}
              />
              <Text style={styles.textVersion}>split by comma (,)</Text>
            </View>
            </View>
            </form>
            {/* <Pressable
              style={[styles.button, styles.buttonClose]}
              onPress={() => this.setAddUserModalVisable(!addUserModalVisable)}
            >
              <Text style={styles.textStyle}>Close</Text>
            </Pressable> */}
            <View style={{flexDirection: 'row',}}>
              <View style={styles.button}>
              <Button
                title="Close"
                onPress={() => this.closeAddUserModal() }
                color="#cc0000"
              />
              </View>
              <View style={styles.button}>
              <Button
                title="Save"
                disabled={!isUserChanged}
                onPress={() => { this.createUser(); }}
              />
              </View>
            </View>
          </View>
        </View>
      </Modal>
    </View>
    );
  }
  updateUser() {
    const { userId, username, password, confirmPassword, userRole, canExportPcc, ownerPcc, agentName } = this.state;

    //console.log('userId=', userId);
    //console.log('username=', username);
    //console.log('password=', password);
    //console.log('confirmPassword=', confirmPassword);
    //console.log('userRole=', userRole);
    
    const self = this;
    PutRequest('api/users/' + userId, { 
      email: username,
      password: password,
      roles: [ userRole ],
      canExportPcc: canExportPcc,
      ownerPcc: ownerPcc,
      agentName: agentName,
    }).then((jsonData) => {
      //console.log("jsonData=", jsonData);
      if (jsonData.ok === false) {
        // error
        self.showError('Update user fail. Plase, check and try again.');
      }
      else {
        self.showMsg('Update user done.');
        this.closeUpdateUserModal();
        this.fetchUsers();
      }
    })
    .catch((e) => {
      console.error(e);
      alert(e);
    })
    .finally(() => {
      
    });
  }
  showUpdateUserModel() {
    this.setUpdateUserModalVisable(true);
  }
  closeUpdateUserModal() {
    this.setState({
      username: '',
      password: '',
      confirmPassword: '',
      isUserChanged: false,
      userRole: 'Common',
      confirmPasswordMessage: '',
      canExportPcc: '',
      ownerPcc: '',
      agentName: '',
    })
    this.setUpdateUserModalVisable(false);
  }
  setUpdateUserModalVisable(visible:boolean) {
    this.setState({ updateUserModalVisable: visible });
  };
  renderUpdateUserModel() {
    const { updateUserModalVisable, username, password, confirmPassword, userRole, isUserChanged, confirmPasswordMessage, canExportPcc, ownerPcc, agentName } = this.state;

    return (
    <View style={styles.centeredView}>
      <Modal
        animationType="slide"
        transparent={false}
        visible={updateUserModalVisable}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          this.setAddUserModalVisable(!updateUserModalVisable);
        }}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <Text style={[styles.modalText, styles.headerText]}>Edit User</Text>
            <Text style={{color: 'red'}}>case-sensitive</Text>
            <form>
            <View style={styles.modalContainer}>
            <View style={styles.modalItem}>
              <Text style={styles.textBold}>Email:</Text>
              <TextInput 
                style={styles.modalInput}
                placeholder='Email'
                value={username}
                onChangeText={(val) => {
                  this.setUsername(val);
                }}
              />
            </View>
            <View style={styles.modalItem}>
              <Text style={styles.textBold}>User Role:</Text>
              <View style={styles.radioInput}>
                <View style={styles.radioInput}>
                  <RadioButton
                  value="Administrator"
                  status={ userRole === 'Administrator' ? 'checked' : 'unchecked'}
                  onPress={() => { this.setUserRole('Administrator') }}
                  />
                  <Text>Administrator Role</Text>
                </View>
                <RadioButton
                  value="Common"
                  status={ userRole === 'Common' ? 'checked' : 'unchecked'}
                  onPress={() => { this.setUserRole('Common') }}
                />
                <Text>Common Role</Text>
              </View>
            </View>
            <View style={styles.modalItem}>
              <Text style={styles.textBold}>New Password:</Text>
              <TextInput 
                style={styles.modalInput}
                placeholder='New Password'
                value={password}
                onChangeText={(val) => {
                  this.setPassword(val);
                }}
                secureTextEntry
              />
            </View>
            <View style={styles.modalItem}>
              <Text style={styles.textBold}>Confirm New Password:</Text>
              <TextInput 
                style={styles.modalInput}
                placeholder='Confirm New Password'
                value={confirmPassword}
                onChangeText={(val) => {
                  this.setConfirmPassword(val);
                }}
                secureTextEntry
              />
              {( confirmPasswordMessage != '' 
                ? <Text style={styles.errorText}>{confirmPasswordMessage}</Text>
                : <></> )}
            </View>
            <View style={styles.modalItem}>
              <Text style={styles.textBold}>Agent Name:</Text>
              <TextInput
                style={styles.modalInput}
                placeholder='Agent Name'
                value={agentName}
                onChangeText={(val) => {
                  this.setAgentName(val);
                }}
                maxLength={80}
              />
            </View>
            <View style={styles.modalItem}>
              <Text style={styles.textBold}>Owner PCC: (* = all PCCs)</Text>
              <TextInput 
                style={styles.modalInput}
                value={ownerPcc}
                onChangeText={(val) => {
                  this.setOwnerPcc(val);
                }}
                maxLength={4}
              />
            </View>
            <View style={styles.modalItem}>
              <Text style={styles.textBold}>Issuing PCC:</Text>
              <TextInput 
                style={styles.modalInput}
                value={canExportPcc}
                onChangeText={(val) => {
                  this.setCanExportPcc(val);
                }}
                multiline={true}
                numberOfLines={3}
              />
              <Text style={styles.textVersion}>split by comma (,)</Text>
            </View>
            </View>
            </form>
            <View style={{flexDirection: 'row',}}>
              <View style={styles.button}>
              <Button
                title="Close"
                onPress={() => this.closeUpdateUserModal() }
                color="#cc0000"
              />
              </View>
              <View style={styles.button}>
              <Button
                title="Save"
                disabled={!isUserChanged}
                onPress={() => { this.updateUser(); }}
              />
              </View>
            </View>
          </View>
        </View>
      </Modal>
    </View>
    );
  }
}

export default UsersScreen;